import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [sendMessageLabel, setSendMessageLabel] = useState("Send Message");
    const [isSending, setIsSending] = useState(false);

    const onSubmit = (data) => {
        setIsSending(true);
        setSendMessageLabel("Sending Message...");

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch("https://api.corvidae.co.nz/contact", requestOptions)
            .then(async (response) => {
                const isJson = response.headers
                    .get("content-type")
                    ?.includes("application/json");
                const data = isJson && (await response.json());
                setIsSending(false);
                if (response.ok) {
                    setSuccessMessage(
                        "Your message has been sent. We will be in touch soon."
                    );
                    setSendMessageLabel("Message Sent");
                } else {
                    setSendMessageLabel("Send Message");
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch((error) => {
                setIsSending(false);
                setSendMessageLabel("Send Message");
                setErrorMessage(
                    "An error has occurred. Please try again later"
                );
            });
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Your Name*"
                        ref={register({ required: "Name is required" })}
                    />
                    {errors.name && (
                        <p className="error">{errors.name.message}</p>
                    )}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Your Email*"
                        ref={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                    {errors.email && (
                        <p className="error">{errors.email.message}</p>
                    )}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        ref={register({
                            required: "Message is required",
                        })}
                    ></textarea>
                    {errors.message && (
                        <p className="error">{errors.message.message}</p>
                    )}
                </div>
                <div className="col-12">
                    {successMessage && (
                        <p className="success mb-5">{successMessage}</p>
                    )}
                    {errorMessage && (
                        <p className="error mb-5">{errorMessage}</p>
                    )}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        disabled={!!successMessage || isSending}
                    >
                        {sendMessageLabel}
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
