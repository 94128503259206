import GA4React from "ga-4-react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const ga4react = new GA4React("G-EV43PTMK17");

(async (_) => {
    await ga4react
        .initialize()
        .then()
        .catch((err) => console.log("Analytics Failure."))
        .finally(() => {
            ReactDOM.render(<App />, document.getElementById("root"));
        });
})();
